import React, { useState } from "react";
import {
  Routes,
  Route,
  Link,
  BrowserRouter,
  useParams,
  useLocation,
} from "react-router-dom";

import logo from "../images/logo_front_2.png";

import Day1 from "../programs/day1.js";
import Day2 from "../programs/day2.js";
import Day3 from "../programs/day3.js";

function Program() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const experience = searchParams.get("experience");
  const goal = searchParams.get("goal");
  const days = searchParams.get("days");

  // console.log(experience, goal, days);

  return (
    <main>
      <img src={logo} />
      <hr />
      <h1>Your Program</h1>
      <br />
      {/* <div>
        <p>Experience: {experience}</p>
        <p>Goal: {goal}</p>
        <p>Days: {days}</p>
      </div> */}
      {/* 
      <table className="program-table">
        <tbody>
          <td className="header" colspan="3">
            Day 1
          </td>
          <tr>
            <td className="set-cell">
              <div className="exercise">
                <strong>Bench Press</strong>
                <br />
                Warm Up
                <br />
                Set 1 – 5 reps / RPE 8<br />
                Set 2 – 8 reps / RPE 8<br />
                Set 3 – 8 reps / RPE 9
              </div>
            </td>
            <td className="superset-cell">
              <div className="superset">
                <strong>*SUPER SET WITH</strong>
                <br />
                Pull Ups
                <br />
                Set 1 – AMRAP / RPE 10
                <br />
                Set 2 – AMRAP / RPE 10
              </div>
            </td>
            <td className="notes-cell">
              <div className="notes">
                Notes:
                <br />
                Remember to maintain proper form. Rest 3-4 minutes between sets.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>
                  <strong>Leg Press</strong>
                  <br />
                  Warm Up
                  <br />
                  Set 1 – 12 reps / RPE 9<br />
                  Set 2 – 10 reps / RPE 9<br />
                  Set 3 – 10 reps / RPE 10
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>
                  Calves
                  <br />
                  Warm Up
                  <br />
                  Set 1 – 16 reps / RPE 9<br />
                  Set 2 – 14 reps / RPE 9<br />
                  Set 3 – 12 reps + Drop Set / RPE 10
                </li>
                <li>
                  <strong>SUPER SET WITH</strong>
                  <br />
                  Lateral Raises
                  <br />
                  Warm Up
                  <br />
                  Set 1 – 16 reps / RPE 9<br />
                  Set 2 – 14 reps / RPE 10
                  <br />
                  Drop Set
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>
                  <strong>Lat Pull Down – Short Grip</strong>
                  <br />
                  Warm Up
                  <br />
                  Set 1 – 12 reps + Drop Set / RPE 10
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table> */}

      {/* Day 1 */}
      <Day1 dayNum={1} goal={goal} experience={experience}></Day1>

      {/* Day 2 */}
      {(() => {
        if (days == 4 || days == 5) {
          return <Day2 dayNum={2} goal={goal} experience={experience}></Day2>;
        } else {
          return <h2 className="restday">Day 2 - Rest Day</h2>;
        }
      })()}

      {(() => {
        if (days == 5) {
          return <Day3 dayNum={3} goal={goal} experience={experience}></Day3>;
        } else {
          return <h2 className="restday">Day 3 - Rest Day</h2>;
        }
      })()}

      {(() => {
        if (days == 3) {
          return <Day2 dayNum={4} goal={goal} experience={experience}></Day2>;
        } else if (days == 4) {
          return <Day3 dayNum={4} goal={goal} experience={experience}></Day3>;
        } else {
          return <h2 className="restday">Day 4 - Rest Day</h2>;
        }
      })()}

      {(() => {
        if (days == 2) {
          return <Day2 dayNum={5} goal={goal} experience={experience}></Day2>;
        } else if (days == 5) {
          return <Day1 dayNum={5} goal={goal} experience={experience}></Day1>;
        } else {
          return <h2 className="restday">Day 5 - Rest Day</h2>;
        }
      })()}

      {(() => {
        if (days == 5 || days == 4) {
          return <Day2 dayNum={6} goal={0} experience={experience}></Day2>;
        } else if (days == 3) {
          return <Day3 dayNum={5} goal={goal} experience={experience}></Day3>;
        } else {
          return <h2 className="restday">Day 6 - Rest Day</h2>;
        }
      })()}

      <h2 className="restday">Day 7 - Rest Day</h2>
      <hr></hr>
      {/* <Day2 dayNum={2} goal={goal} experience={experience}></Day2> */}

      {/* {(() => {
        if (days < 3) {
          return <Day1 dayNum={3} goal={goal} experience={experience} />;
        } else {
          return null; // or any other fallback JSX if needed
        }
      })()} */}

      <br></br>

      <table className="program-table">
        <tbody>
          <td className="header" colSpan="3">
            Glossary
          </td>
          <tr>
            <td className="set-cell">
              <div className="exercise">
                <strong>RPE</strong> - Rate of Perceived Exertion
                <br></br>
                RPE measures how hard your body works during a set. RPE is
                within this program to determine the load/weight used within an
                excises. RPE can also be convert to RIR (Reps in reserve) using{" "}
                <strong>RIR = RPE - 10</strong>. For example, an RPE of 8 is
                equal to 2 reps in reserve.
                <br></br>
                For example, if I'm able to bench press 100kg for 5 reps at an
                RPE of 10 (maximum effort), Therefore, I could do 100kg for 4
                reps at an RPE of 8
              </div>
              <div className="exercise">
                <strong>Super Set</strong>
                <br></br>
                Super sets are when you train different muscle groups after each
                other. This allows you to train when one of your muscle groups
                are recovering.
                <br></br>
                There are no advances to supersets apart from the time savings
                and that they are fun!
              </div>
              <div className="exercise">
                <strong>Drop Set</strong>
                <br></br>
                Drop sets are an technique to increase intensity. Drops sets are
                sets utilizing multiple weights within none or minimal rest
                between switching weights. Drop sets are initially taken to
                technical failure (where form does not break down) and then a
                secondary weight is selected to push past failure.
                <br></br>
                Drop sets allow high amounts of muscle stimulation but come with
                a higher recovery toll and therefore are generally positioned as
                the final set for a muscle group.
              </div>
              <div className="exercise">
                <strong>Long Length Partials (LLP)</strong>
                <br></br>
                LLPs like drop sets are an technique to increase intensity. LLPs
                are partial reps that are done within the lengthen/stretched
                part of the muscle.
                <br></br>
                LLPs allow more reps to be completed when full range of motion
                cannot be done anymore (due to fatigue). Here is an example of
                LLPs being used within set, If I would be doing machine leg
                curls and have completed 10 reps to failure I may do 4 LLPs reps
                where I limit the range of motion.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <span style={{ marginTop: "20px" }}>What something Different?</span>
      <Link className="link button" to="/create" href="question.html">
        Generate Again
      </Link>
      <br />
      <p>
        This is just a guide, we recommend changing, adding and modifying
        excises and workouts to suit your personal situation.
        <br></br>
        Have any questions? We are always happy to help out! Message us on
        social media or email us here{" "}
        <a href="mailto:programs@fuzesupplements.com.au">
          programs@fuzesupplements.com.au
        </a>
      </p>
      <h3>Version 0.2 BETA</h3>
      <p>
        Disclaimer: Fuze Supplements is not liable to any injuries or advert
        effects when this training is conducted.
      </p>
    </main>
  );
}

export default Program;
