import React, { useState } from "react";
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";

import logo from "../images/logo_front_2.png";

function Home() {
  return (
    <main>
      <img src={logo} />
      <hr />
      <h1>FREE TRAINING PROGRAM</h1>
      {/* <p>
        At Fuze Supplements we aim to create community and engage with our
        customers as much as possible, this program is designed to get people in
        the gym, lifting and interacting!
      </p> */}
      <h3>What Is This Program?</h3>
      <hr />
      <p>
        This program is a fully body split designed for hypertrophy (muscle
        growth) and strength which has a focus on high-intensity and low-volume
        sets.
        <br />
        <br />
        It is designed for people who just love lifting, just like us!
      </p>
      <h3>Benefits To This Program</h3>
      <table className="pros-cons-table table-reg">
        <tbody>
          <tr>
            <th>Pros</th>
            <th>Cons</th>
          </tr>
          <tr>
            <td>
              <ul>
                <li>Enjoyable and fun program due to high-intensity</li>
                <li>Very Optimal for muscle growth and strength</li>
                <li>Minimises the impact of fatigue</li>
                <li>Minimises time spent in the gym</li>
              </ul>
            </td>
            <td>
              {" "}
              <ul>
                <li>May be hard for new lifters starting out</li>
                <li>Adjusting to full-body split can be difficult</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="pros-cons-table program-table table-mbl">
        <tbody>
          <tr>
            <th>Pros</th>
          </tr>
          <tr>
            <td>
              <ul>
                <li>Enjoyable program</li>
                <li>Very Optimal for muscle growth and strength</li>
                <li>
                  Good recovery between sessions, (You won’t be sore after every
                  session)
                </li>
                <li>Very Effective for little time in the gym</li>
              </ul>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th>Cons</th>
          </tr>
          <tr>
            <td>
              <ul>
                <li>Can be difficult to keep track of</li>
                <li>Programing can be difficult if modification is made</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Lets Check Started...</p>
      <Link className="link button" to="/create" href="question.html">
        Create
      </Link>
      <br />
    </main>
  );
}

export default Home;
