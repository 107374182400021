import React, { useState } from "react";

function Day2(props) {
  const [goal, setGoal] = useState(props.goal);
  const [exp, setexp] = useState(props.exp);
  const dayNumber = props.dayNum;

  const leg2 = () => {
    return (
      <div>
        {goal == 1 ? (
          <>
            <strong>Deadlift</strong>
            <br />
            Warm Up
            <br />
            Set 1 – 5 reps / RPE 8<br />
            Set 2 – 8 reps / RPE 8<br />
            Set 3 – 8 reps / RPE 9
          </>
        ) : (
          <>
            <strong>Leg Curls</strong>
            <br />
            Warm Up
            <br />
            Set 1 – 12 reps / RPE 9<br />
            Set 2 – 10 reps / RPE 9<br />
            Set 3 – 8 reps + Drop Set / RPE 10
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <table className="program-table">
        <tbody>
          <td className="header" colSpan="3">
            Day {dayNumber}
          </td>
          <tr>
            <td className="set-cell">
              <div className="exercise">
                <strong>Shoulder Press</strong>
                <br />
                Warm Up
                <br />
                Set 1 – 8 reps / RPE 8<br />
                Set 2 – 10 reps / RPE 8<br />
                Set 3 – 10 reps / RPE 9
              </div>
            </td>
            <td className="superset-cell">
              <div className="superset">
                <strong>SUPER SET WITH</strong>
                <br />
                Dumbbell Bicep Curls
                <br />
                Warm Up
                <br />
                Set 1 – 12 reps / RPE 9
                <br />
                Drop set into hammer curls
                <br />
                Set 2 – 10 reps / RPE 10
                <br />
                Drop set into hammer curls
              </div>
            </td>
            <td className="notes-cell">
              <div className="notes">
                Notes:
                <br />
                Have small rest between shoulder press and bicep curls.
                <br></br>
                For bicep curls complete as many regular curls as possible and
                then move in hammer curls as they are slightly easier.
              </div>
            </td>
          </tr>
          <tr>
            <td className="set-cell">
              <div className="exercise">{leg2()}</div>
            </td>
            <td className="superset-cell">
              <div className="superset"></div>
            </td>
            <td className="notes-cell">
              <div className="notes">
                Notes:
                <br />
                {goal == 1 ? (
                  <>
                    Make sure you warm up and progressive add weight. Use a
                    range of dynamic stretches and foam rolling if needed.
                    <br></br>
                    Make sure proper form is mantained, use which ever deadlift
                    variations you perfer (sumo or conventional).
                  </>
                ) : (
                  <>
                    Make sure you warm up and progressive add weight. Use a
                    range of dynamic stretches and foam rolling if needed.
                    <br></br>
                    Make sure you are properly getting deep enough, a good rule
                    is 90 degrees or less within your legs.
                  </>
                )}
              </div>
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td className="set-cell">
              <div className="exercise">
                <strong>Tricep Push Down</strong>
                <br />
                Warm Up
                <br />
                Set 1 – 16 reps / RPE 9<br />
                Set 2 – 14 reps / RPE 9<br />
                Set 3 – 12 reps + Drop Set / RPE 10
              </div>
            </td>
            <td className="superset-cell">
              <div className="superset">
                SUPER SET WITH
                <br />
                <strong>Face Pulls / Rear delt fly’s</strong>
                <br />
                Warm Up
                <br />
                Set 1 – 16 reps / RPE 9<br />
                Set 2 – 14 reps / RPE 10
                <br />
                Drop Set / Long length Partials
              </div>
            </td>
            <td className="notes-cell">
              <div className="notes">
                Notes:
                <br />
                Any tricep push down can be used. Some good examples included
                cable push down with a bar or rope, tricep overhead extension or
                even push down on a assisted pull up machine.
              </div>
            </td>
          </tr>
          <tr>
            <td className="set-cell">
              <div className="exercise">
                <strong>Cable Bicep Curls</strong>
                <br />
                Set 1 – 16 reps / RPE 10
              </div>
            </td>
            <td className="superset-cell">
              <div className="superset"></div>
            </td>
            <td className="notes-cell">
              <div className="notes">
                Notes:
                <br />
                Can use a rope or bar for this one. Even single arm curls are a
                good variations.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default Day2;
