// import './App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";

import "./style/App.css";

import Footer from "./components/Footer.js";

import Home from "./pages/Home.js";
import Create from "./pages/Create.js";
import Program from "./pages/program.js";

function App() {
  const PageRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/create" element={<Create />}></Route>
        <Route path="/program" element={<Program />}></Route>
        <Route path="*" element={<Home />} />
      </Routes>
    );
  };

  return (
    <div className="App">
      <BrowserRouter>
        <PageRoutes></PageRoutes>
      </BrowserRouter>

      <Footer></Footer>
    </div>
  );
}

export default App;
