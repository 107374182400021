import React, { useState } from "react";
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";

function mapExperienceToWord(value) {
  if (value >= 100) {
    return (
      <>
        You are a <strong>Certified gym bro/girlie</strong>
      </>
    );
  } else if (value >= 80) {
    return (
      <>
        You are an <strong>Expert lifer</strong>
      </>
    );
  } else if (value >= 60) {
    return (
      <>
        You are an <strong>Advanced lifter</strong>
      </>
    );
  } else if (value >= 40) {
    return (
      <>
        You are an <strong>Intermediate lifter</strong>
      </>
    );
  } else if (value >= 20) {
    return (
      <>
        You have <strong>some experience</strong> in the gym
      </>
    );
  } else if (value >= 0) {
    return (
      <>
        You are <strong>new to the Gym</strong>
      </>
    );
  } else {
    return "";
  }
}

function mapGoaltoWord(value) {
  if (!value) {
    return "Muscle growth";
  } else if (value) {
    return "Strength + Muscle growth";
  } else {
    return "N/A";
  }
}

function Create() {
  const [currentStep, setCurrentStep] = useState(1);
  const [currentInputClass, setCurrentInputClass] = useState(1);

  const [goal, setGoal] = useState(2);
  const [experience, setExperience] = useState(0);
  const [cake, setCake] = useState(2);
  const [days, setDays] = useState(0);

  function handleExperience(event) {
    setExperience(event.target.value);
  }

  const handleNext = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return (
          <div>
            <h3>What is your experience in the gym?</h3>
            <p>On a scale of 0 to 100</p>

            <div className="slider-container">
              <label>0</label>
              <div className="slider-div">
                <input
                  onChange={handleExperience}
                  type="range"
                  min={1}
                  max={100}
                  defaultValue={50}
                  className="slider"
                  id=""
                />
              </div>
              <label>100</label>
            </div>
            {/* <p>Your experience is {experience}/100</p> */}
            <p>{mapExperienceToWord(experience)}</p>
          </div>
        );
      case 2:
        return (
          <div>
            <br></br>
            <h3>What is your main goal in the gym?</h3>
            <div className="form-toggle">
              <div>
                <button onClick={() => setGoal(0)} disabled={goal === 0}>
                  Focus just on hypertrophy (Muscle growth)?
                </button>
                <button onClick={() => setGoal(1)} disabled={goal === 1}>
                  Do you want to gain strength and gain muscle?
                </button>
              </div>
            </div>
            <p>
              (Gaining strength includes training the main 3, Bench press, Squat
              and Deadlift)
            </p>
          </div>
        );
      case 3:
        return (
          <div>
            <br></br>
            <h3>How many days a week can you train?</h3>
            <div className="form-toggle">
              <div>
                <button onClick={() => setDays(2)} disabled={days === 2}>
                  2
                </button>
                <button onClick={() => setDays(3)} disabled={days === 3}>
                  3
                </button>
                <button onClick={() => setDays(4)} disabled={days === 4}>
                  4
                </button>
                <button onClick={() => setDays(5)} disabled={days === 5}>
                  5
                </button>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="flex">
            <h3>That's Everything!</h3>
            <p>
              <b>Experience:</b> {mapExperienceToWord(experience)}
            </p>
            <p>
              <b>Goal:</b> {mapGoaltoWord(goal)}
            </p>
            <p>
              <b>Availability:</b> {days} days a week
            </p>

            <div className="button-container">
              <Link
                className="link button float"
                // href="/program"
                to={`/program?experience=${experience}&goal=${goal}&days=${days}`}
              >
                <span>Create</span>
              </Link>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="page-container">
      <h1>GENERATE A PROGRAM</h1>
      <hr></hr>
      <div className="main box">
        <div className="input-container">{renderStep(currentStep)}</div>

        <br></br>
      </div>
      <div className="form-navigation">
        <h3>{currentStep}/4</h3>
        <div>
          <button onClick={handlePrevious} disabled={currentStep === 1}>
            Previous
          </button>
          <button onClick={handleNext} disabled={currentStep === 4}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Create;
