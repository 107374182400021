import React, { useState } from "react";

function Day1(props) {
  //   const exp = props.exp;
  //   const goal = props.goal;

  const [goal, setGoal] = useState(props.goal);
  const [exp, setexp] = useState(props.exp);
  const dayNumber = props.dayNum;
  //   console.log(goal);

  const leg1 = () => {
    // console.log("RE", goal);
    // if (goal == 1) {
    //   return (
    //     <>
    //       <strong>Barbell Back Squat</strong>
    //       <br />
    //       Warm Up
    //       <br />
    //       Set 1 – 5 reps / RPE 8<br />
    //       Set 2 – 8 reps / RPE 8<br />
    //       Set 3 – 8 reps / RPE 9
    //     </>
    //   );
    // } else {
    //   return (
    //     <>
    //       <strong>Leg Press</strong>
    //       <br />
    //       Warm Up
    //       <br />
    //       Set 1 – 5 reps / RPE 8<br />
    //       Set 2 – 8 reps / RPE 8<br />
    //       Set 3 – 8 reps / RPE 9
    //     </>
    //   );
    // }
    return (
      <div>
        {goal == 1 ? (
          <>
            <strong>Barbell Back Squat</strong>
            <br />
            Warm Up
            <br />
            Set 1 – 5 reps / RPE 8<br />
            Set 2 – 8 reps / RPE 8<br />
            Set 3 – 8 reps / RPE 9
          </>
        ) : (
          <>
            <strong>Leg Press</strong>
            <br />
            Warm Up
            <br />
            Set 1 – 10 reps / RPE 8<br />
            Set 2 – 12 reps / RPE 8<br />
            Set 3 – 12 reps / RPE 9
          </>
        )}
      </div>
    );
  };

  return (
    <table className="program-table">
      <tbody>
        <td className="header" colSpan="3">
          Day {dayNumber}
        </td>
        <tr>
          <td className="set-cell">
            <div className="exercise">
              <strong>Bench Press</strong>
              <br />
              Warm Up
              <br />
              {goal == 1 ? (
                <>
                  Set 1 – 5 reps / RPE 8<br />
                  Set 2 – 8 reps / RPE 8<br />
                  Set 3 – 8 reps / RPE 9
                </>
              ) : (
                <>
                  Set 1 – 10 reps / RPE 8<br />
                  Set 2 – 8 reps / RPE 8<br />
                  Set 3 – 12 reps / RPE 9
                </>
              )}
            </div>
            <div></div>
          </td>
          <td className="superset-cell">
            <div className="superset">
              SUPER SET WITH
              <br />
              <strong>Pull Ups </strong>
              <br />
              Set 1 – 12 REPS / REP 9
              <br />
              Set 2 – 12 REPS / REP 10
            </div>
          </td>
          <td className="notes-cell">
            <div className="notes">
              Notes:
              <br />
              Warm up as you like, preferably use dynamic stretching and at
              least 2 sets progressive in weight. Rest 3-4 minutes between bench
              sets. <br></br>
              For pull ups use either assisted (banded), body weight or weighted
              variations to get within rep range.
            </div>
          </td>
        </tr>
        <tr>
          <td className="set-cell">
            <div className="exercise">{leg1()}</div>
          </td>
          <td className="superset-cell"></td>
          <td className="notes-cell">
            <div className="notes">
              Notes:
              <br />
              Warm up as you like, preferably use dynamic stretching and at
              least 2 sets progressive in weight. Rest 3-4 minutes between sets.
            </div>
          </td>
        </tr>
        <tr>
          <td className="set-cell">
            <div className="exercise">
              <strong>Calves (Standing of Seated)</strong>
              <br />
              Warm Up
              <br />
              Set 1 – 16 reps / RPE 9<br />
              Set 2 – 14 reps / RPE 9<br />
              Set 3 – 12 reps + Drop Set / RPE 10
            </div>
          </td>
          <td className="superset-cell">
            <div className="superset">
              SUPER SET WITH
              <br />
              <strong>Lateral Raises</strong>
              <br />
              Warm Up
              <br />
              Set 1 – 16 reps / RPE 9<br />
              Set 2 – 14 reps + Drop Set / RPE 10
              <br />
            </div>
          </td>
          <td className="notes-cell">
            <div className="notes">
              Notes:
              <br />
              Focus on concentric (lowering part) of the calve raises, try pause
              2-3 seconds at the top and bottom and 3 seconds down.
              <br></br> <br></br>
              Lateral raises can be used with dumbbells or cables. Try single
              arm lateral raises as an alterative.
            </div>
          </td>
        </tr>
        <tr>
          <td className="set-cell">
            <div className="exercise">
              <strong>Lat Pull Down – Short Grip</strong>
              <br />
              Warm Up
              <br />
              Set 1 – 12 reps + Drop Set / RPE 10
            </div>
          </td>
          <td className="superset-cell">
            <div className="superset"></div>
          </td>
          <td className="notes-cell">
            <div className="notes">
              Notes:
              <br />
              One quality set of lat pull down, focus on muscle contractions.
              Drop weight ~20% for drop set and maintain form.
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Day1;
