import React, { useState } from "react";

function Day3(props) {
  const [goal, setGoal] = useState(props.goal);
  const [exp, setexp] = useState(props.exp);
  const dayNumber = props.dayNum;

  return (
    <table className="program-table">
      <tbody>
        <td className="header" colSpan="3">
          Day {dayNumber}
        </td>
        <tr>
          <td className="set-cell">
            <div className="exercise">
              <strong>Dumbbell Bench Press</strong>
              <br />
              Warm Up
              <br />
              Set 1 – 10 reps / RPE 8<br />
              Set 2 – 8 reps / RPE 8<br />
              Set 3 – 12 reps / RPE 9
            </div>
            <div></div>
          </td>
          <td className="superset-cell">
            <div className="superset">
              SUPER SET WITH
              <br />
              <strong>Single Arm Dumbbell Rows</strong>
              <br />
              Set 1 – 12 REPS / REP 9
              <br />
              Set 2 – 10 REPS / REP 10
            </div>
          </td>
          <td className="notes-cell">
            <div className="notes">
              Notes:
              <br />
              Warm up as you like, preferably use dynamic stretching and at
              least 2 sets progressive in weight. Rest 3 minutes between bench
              sets. <br></br>
              Dumbbell rows can be done one the same bench, focus on muscle
              contractions for this one.
            </div>
          </td>
        </tr>
        <tr>
          <td className="set-cell">
            <div className="exercise">
              <strong>Leg Extension</strong>
              <br />
              Warm Up
              <br />
              Set 1 – 14 reps / RPE 8<br />
              Set 2 – 12 reps / RPE 8<br />
              Set 3 – 10 reps / RPE 10
            </div>
          </td>
          <td className="superset-cell"></td>
          <td className="notes-cell">
            <div className="notes">
              Notes:
              <br />
              Warm up as you like, preferably use dynamic stretching and at
              least 2 sets progressive in weight.
              <br></br>
              Hold 3 seconds at the top and control concentric.
            </div>
          </td>
        </tr>
        <tr>
          <td className="set-cell">
            <div className="exercise">
              <strong>Calves (Standing of Seated)</strong>
              <br />
              Warm Up
              <br />
              Set 1 – 16 reps / RPE 9<br />
              Set 2 – 14 reps / RPE 9<br />
              Set 3 – 12 reps + Drop Set / RPE 10
            </div>
          </td>
          <td className="superset-cell">
            <div className="superset">
              SUPER SET WITH
              <br />
              <strong>Lateral Raises</strong>
              <br />
              Warm Up
              <br />
              Set 1 – 16 reps / RPE 9<br />
              Set 2 – 14 reps + Drop Set / RPE 10
              <br />
            </div>
          </td>
          <td className="notes-cell">
            <div className="notes">
              Notes:
              <br />
              Focus on concentric (lowering part) of the calve raises, try pause
              2-3 seconds at the top and bottom and 3 seconds down.
              <br></br> <br></br>
              Lateral raises can be used with dumbbells or cables. Try single
              arm lateral raises as an alterative.
            </div>
          </td>
        </tr>
        <tr>
          <td className="set-cell">
            <div className="exercise">
              <strong>Machine Rows</strong>
              <br />
              Warm Up
              <br />
              Set 1 – 12 reps + Drop Set / RPE 10
            </div>
          </td>
          <td className="superset-cell">
            <div className="superset"></div>
          </td>
          <td className="notes-cell">
            <div className="notes">
              Notes:
              <br />
              One quality set of rows, focus on muscle contractions again. Drop
              weight ~20% for drop set and maintain form.
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Day3;
